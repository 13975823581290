import { Link } from 'gatsby'
import React from 'react'
import SEO from '../components/seo/seo'
import H from '../design/H'
import Layout from '../layout'

export default () => (
  <Layout>
    <SEO />
    <div className="container mx-auto px-4 py-8">
      <H>Diese Seite existiert nicht</H>
      <p className="max-w-xs my-6 text-gray-700 leading-relaxed">
        Wie auch immer du hier hingekommen bist.
      </p>
      <p className="mt-4 mb-8">
        <Link to="/" className="btn">
          Zur Startseite
        </Link>
      </p>
    </div>
  </Layout>
)
